/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-tabs */
/* eslint-disable no-const-assign */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/require-default-props */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import MenuItem from 'Component/DesktopMenuItem';
import Image from 'Component/Image';
import Link from 'Component/Link';
import StoreSwitcher from 'Component/StoreSwitcher';
import VehicleFinder from 'Component/VehicleFinder';
import { MenuType } from 'Type/Menu.type';
import { isAusStore, isEurStore, isUsaStore } from 'Util/FrontRunner/Store';
import { getBlackFridayPromo, getSalesPromotion, getXmasPromo } from 'Util/FrontRunner/Store/Promotions';
import {
    isB2BUser,
    scrollToTop
} from 'Util/Helper';
import { getSortedItems } from 'Util/Menu';
import { debounce } from 'Util/Request';

import './HeaderMenu.style';

export const SCROLL_DEBOUNCE_DELAY = 10;

/** @namespace Pwa/Component/HeaderMenu/Component/HeaderMenuComponent */
export class HeaderMenuComponent extends PureComponent {
    static propTypes = {
        menu: MenuType.isRequired,
        activeMenuItemsStack: PropTypes.array.isRequired,
        handleSubcategoryClick: PropTypes.func.isRequired,
        closeMenu: PropTypes.func.isRequired,
        onCategoryHover: PropTypes.func.isRequired,
        onCategoryClick: PropTypes.func.isRequired,
        device: PropTypes.any,
        activeMenu: PropTypes.any
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            vehicle: true
        };
    }

    componentDidMount() {
        const { closeMenu } = this.props;

        this.debouncedCloseMenu = debounce(closeMenu, SCROLL_DEBOUNCE_DELAY);

        window.addEventListener('scroll', this.debouncedCloseMenu);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.debouncedCloseMenu);
    }

    renderDesktopSubLevelItems(item, mods) {
        let {
            label = '', name, image, description
        } = item;

        const { closeMenu, activeMenuItemsStack } = this.props;
        if (isEmpty(label) && !isEmpty(name)) {
            label = name;
        }

        return (
            <MenuItem
              activeMenuItemsStack={ activeMenuItemsStack }
              item={ item }
              itemMods={ mods }
              closeMenu={ closeMenu }
              isLink
              key={ label }
            />
        );
    }

    renderSubSubMenu(sub_sub_category = []) {
        if (!sub_sub_category || sub_sub_category == null || sub_sub_category.length < 1) {
            return null;
        }

        const isBanner = 'Menu-ItemFigure_type_banner';
        const isLogo = 'Menu-ItemFigure_type_logo';

        const mods = {
            isBanner: !!isBanner,
            isLogo: !!isLogo
        };

        const item_id = 'renderSubSubMenu';

        return (
            <div
              block="Menu"
              elem="SubSubLevelDesktop"
              key={ item_id }
            >
                { sub_sub_category.map((item) => this.renderDesktopSubLevelItems(item, mods)) }
            </div>
        );
    }

    renderDesktopSubLevel(category) {
        const { device } = this.props;
        const { sub_category, item_class, label } = category;
        const childrenArray = getSortedItems(Object.values(children));

        if (device.isMobile || !childrenArray.length) {
            return null;
        }

        const item_id = 'renderDesktopSubLevel';

        const isBanner = item_class == 'Menu-ItemFigure_type_banner';
        const isLogo = item_class == 'Menu-ItemFigure_type_logo';
        const mods = {
            isBanner: !!isBanner,
            isLogo: !!isLogo
        };

        return (
            <div
              block="Menu"
              elem="SubLevelDesktop"
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="ItemList"
                  mods={ { ...mods } }
                >
                    { childrenArray.map((item) => this.renderDesktopSubLevelItems(item, mods)) }
                </div>
            </div>
        );
    }

    renderSubLevelItems = (item) => {
        const {
            handleSubcategoryClick,
            activeMenuItemsStack,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        let {
            label,
            name,
            sub_category = [],
            item_class = '',
            url = ''
        } = item;

        if (isEmpty(label) && !isEmpty(name)) {
            label = name;
        }

        const isBanner = item_class == 'Menu-ItemFigure_type_banner';
        const childrenArray = Object.values(sub_category);
        const subcategoryMods = { type: 'subcategory' };

        if (childrenArray.length && device.isMobile) {
            return (
                <div
                  key={ label }
                  // TODO: split into smaller components
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  role="button"
                >
                    <MenuItem
                      activeMenuItemsStack={ activeMenuItemsStack }
                      item={ item }
                      itemMods={ subcategoryMods }
                      onCategoryHover={ onCategoryHover }
                      closeMenu={ closeMenu }
                    />
                    { this.renderSubLevel(item) }
                </div>
            );
        }

        return (
            <div
              block="Menu"
              elem="SubItemWrapper"
              key={ item_id }
              mods={ { isBanner } }
            >
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  closeMenu={ closeMenu }
                  isLink
                />
                { this.renderDesktopSubLevel(item) }
            </div>
        );
    };

    renderSubLevel(category) {
        const { activeMenuItemsStack } = this.props;
        let {
            label, name, sub_category = [], url
        } = category;
        const childrenArray = getSortedItems(Object.values(sub_category));
        const isVisible = activeMenuItemsStack.includes(label);
        const subcategoryMods = { type: 'subcategory' };
        if (isEmpty(label) && !isEmpty(name)) {
            label = name;
        }

        return (
            <div
              block="Menu"
              elem="SubMenu"
              mods={ { isVisible } }
              key={ label }
            >
                <div
                  block="Menu"
                  elem="ItemList"
                  mods={ { ...subcategoryMods } }
                >
                    { childrenArray.map(this.renderSubLevelItems) }
                </div>
            </div>
        );
    }

    renderPromotionCms() {
        const { closeMenu } = this.props;
        const { header_content: { header_cms } = {} } = window.contentConfiguration;

        if (!isEmpty(window.contentConfiguration) && header_cms) {
            return <CmsBlock identifier={ header_cms } />;
        }

        return (
            <div block="Menu" elem="Promotion">
                <h3 block="Menu" elem="PageLink">
                    <Link
                      to="/about-us"
                      onClick={ closeMenu }
                      block="Menu"
                      elem="Link"
                    >
                        { __('ABOUT US') }
                    </Link>
                </h3>
                <h3 block="Menu" elem="PageLink">
                    <Link
                      to="/about-us"
                      onClick={ closeMenu }
                      block="Menu"
                      elem="Link"
                    >
                        { __('CONTACTS') }
                    </Link>
                </h3>
                <div block="Menu" elem="Social">
                    <CmsBlock identifier="social-links" />
                </div>
            </div>
        );
    }

    renderSubMenuDesktopItems = (item) => {
        const { label, sub_category } = item;
        if (isEmpty(sub_category)) {
            return null;
        }

        if (!Object.keys(sub_category).length) {
            return null;
        }

        const { activeMenuItemsStack } = this.props;
        const isVisible = activeMenuItemsStack.includes(label);

        if (!isVisible) {
            return null;
        }

        return (
            <div
              block="Menu"
              elem="SubCategoriesWrapper"
              mods={ { isVisible } }
              key={ label }
            >
                <div
                  block="Menu"
                  elem="SubCategoriesWrapperInner"
                  mods={ { isVisible } }
                >
                    <div
                      block="Menu"
                      elem="SubCategories"
                    >
                        { this.renderSubLevel(item) }
                    </div>
                    { this.renderAdditionalInformation() }
                </div>
            </div>
        );
    };

    renderVehicleFinder = (key, title) => (<div className="Menu-MainCategories second_menu vehicleFind"><VehicleFinder /></div>);

    renderSubMenuDesktop(subMenu) {
        const { device } = this.props;
        if (device.isMobile || isEmpty(subMenu)) {
            return null;
        }

        const { label, sub_category, type } = subMenu;
        const key = type;

        // if (type == 'vehicle_finder') {
        //     return this.renderVehicleFinder(key, label);
        // }

        if (isEmpty(sub_category)) {
            return null;
        }

        const childrenArray = getSortedItems(Object.values(sub_category));

        return childrenArray.map(this.renderSubMenuDesktopItems);
    }

    renderAdditionalInformation(checkMobile = false) {
        const { device } = this.props;

        if (checkMobile && !device.sMobile) {
            return null;
        }

        return (
            <>
                { this.renderStoreSwitcher() }
                { /* { this.renderCurrencySwitcher() } */ }
                { this.renderPromotionCms() }
            </>
        );
    }

    renderFirstLevelItems(item) {
        const {
            activeMenuItemsStack,
            handleSubcategoryClick,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        let {
            sub_category = [], sub_sub_category = [], label, name, url, image, description
        } = item;
        let hasSubCategory = true;
        if (isEmpty(sub_category)) {
            sub_category = [];
        }
        if (sub_category.length === 0) {
            hasSubCategory = false;
        }
        const childrenArray = Object.values(sub_category);
        const itemMods = { type: 'main' };
        if (isEmpty(label) && !isEmpty(name)) {
            label = name;
        }

        if (childrenArray.length && device.isMobile) {
            return (
                <div
                  // TODO: split into smaller components
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  block="Menu"
                  elem="SubCatLink"
                  role="button"
                >
                    <MenuItem
                      activeMenuItemsStack={ activeMenuItemsStack }
                      item={ item }
                      itemMods={ itemMods }
                      onCategoryHover={ onCategoryHover }
                      closeMenu={ closeMenu }
                    />
                    { this.renderSubLevel(item) }
                </div>
            );
        }

        return (
            <div>
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  itemMods={ itemMods }
                  onCategoryHover={ onCategoryHover }
                  closeMenu={ closeMenu }
                  isLink
                  onClick={ closeMenu }
                />

                { (hasSubCategory) && (
                <div className="submenu">
                    <ul
                      block="SubMenu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ label }
                    >
                        { sub_category.map(this.renderFirstLevel) }
                        {/* { (hasSubCategory) && (
                            <>
                                { this.renderDetails({
                                    label, sub_sub_category, image, description
                                }) }
                            </>
                        ) } */}
                    </ul>
                </div>
                ) }
            </div>

        );
    }

    moveToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    renderFirstLevel = (item) => {
        let {
            label, name, sub_category = [], sub_sub_category = [], url, image, description, menu_position
        } = item;

        if (isEmpty(item)) {
            return null;
        }
        if (isEmpty(label) && !isEmpty(name)) {
            label = name;
        }
        let hasSubCategory = true;
        if (sub_category?.length === 0) {
            hasSubCategory = false;
        }

        return (
            <li key={ label } block="Menu" elem="Item" onClick={ () => this.moveToTop() }>
                { this.renderFirstLevelItems(item) }

               {/* { (!hasSubCategory) && (
                   <>
                    { ' ' }
                    { this.renderDetails({
                        label, sub_sub_category, image, description
                    }) }
                   </>
               ) } */}
            </li>
        );
    };

    renderDetails =({
        label, sub_sub_category = [], image, description
    }) => {
        let imageAttr = null;
        const testImageAttr = (/\.(gif|jpg|jpeg|tiff|png)$/i).test(image);
        if (testImageAttr) {
            imageAttr = (
                <Image
                  src={ image }
                  alt={ `Front Runner Dometic ${label}` }
                  title={ `Front Runner Dometic ${label}` }
                  ratio="custom"
                  mix={ { block: 'category', elem: 'Picture' } }
                  loading="lazy"
                  isPlaceholder={ !image }
                />
            );
        }

        return (
            <div className="category-details-image">
              <div className="category-description">
                { this.renderSubSubMenu(sub_sub_category) }
                {/* { description } */}
              </div>
              { imageAttr }
            </div>
        );
    };

    renderTopLevel =() => {
        let { subMenu = {}, closeMenu } = this.props;
        if (isEmpty(subMenu)) {
            const { menu = {} } = this.props;
            const { header_content = [{}] } = menu;
            const [{ shop: [{ category }] }] = header_content;
            subMenu = {
                label: 'Shop',
                type: 'shop',
                sub_category: category,
                url: ''
            };
        }

        const { sub_category: categoryArray, label: mainCategoriesTitle } = subMenu;
        const {
            label, name, sub_category = [], type
        } = subMenu;

        const key = type;

        // if (type == 'vehicle_finder') {
        //     return this.renderVehicleFinder(key, label);
        // }

        if (!categoryArray || !categoryArray.length) {
            return null;
        }

        const childrenArray = getSortedItems(Object.values(categoryArray));

        return (
            <div block="Menu" elem="MainCategories second_menu subMenus">
                <div className="MainCategoriesWrap">
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle.value }
                    >
                        { childrenArray.map(this.renderFirstLevel) }
                    </ul>
                    { this.renderAdditionalInformation(true) }
                </div>
                <div
                  block="Menu"
                  elem="OverlayBox"
                  onClick={ closeMenu }
                />
            </div>
        );
    };

    renderStoreSwitcher() {
        const { device } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return <StoreSwitcher />;
    }

    renderHeaderLevel = () => {
        const { menu = {}, activeMenu, customer = {} } = this.props;
        const { is_b2b = '' } = customer;
        const { header_content = [{}] } = menu;
        const [{
            shop: [{ category }], shop_by_adventure, dometic_outdoor, explore, contact: [{ label, url, url_resolver }], discover_more = [{}]
        }] = header_content;

        const [{ category: disCategory }] = discover_more;
        let categoryArray = [
            {
                label: __('Shop Online'),
                type: 'category',
                sub_category: category,
                url: ''
            },
            // {
            //     label: __('Outfit your vehicle'),
            //     type: 'vehicle_finder',
            //     sub_category: [],
            //     url: ''
            // },
            {
                label: __("What's New"),
                type: 'whats_new',
                sub_category: [],
                url: '/whats-new'
            },
            {
                label: __('Discover More'),
                type: 'disCategory',
                sub_category: disCategory,
                url: ''
            }
            // {
            //     label: __('Adventure'),
            //     type: 'shop_by_adventure',
            //     sub_category: shop_by_adventure,
            //     url: ''

            // },
            // {
            //     label: __('Explore'),
            //     type: 'explore',
            //     sub_category: explore,
            //     url: ''

            // }
        ];

        // Check if B2B customer (Use local storage check)
        const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;
        const isB2BCheck = isB2BUser(is_b2b);

        // Add appropriate Outlet link based on user type
        if (isUsaStore() || isEurStore()) {
            if (isB2BCheck) {
                categoryArray.push({
                    label: __('Outlet'),
                    type: 'outlet-b2b',
                    sub_category: [],
                    url: '/outlet-b2b',
                    badge: __('NEW')
                });
            } else {
                categoryArray.push({
                    label: __('Outlet'),
                    type: 'outlet',
                    sub_category: [],
                    url: '/outlet',
                    badge: __('NEW')
                });
            }
        }

        // Gift Guide
        // if (isAusStore() && !isB2B) {
        //     const dealsItems = {
        //         label: __('Gift Guide'),
        //         type: 'deals',
        //         sub_category: [],
        //         url: '/gift-guide'
        //     };

        //     categoryArray.splice(1, 0, dealsItems);
        // }

        // Show deals link when sale period is active
        const salesPromotion = getSalesPromotion();
        const { active = false } = salesPromotion;
        let badge = null;

        if (active && !isB2BCheck) {
            // if (isEurStore()) {
            //     badge = __('NEW');
            // }

            let dealsUrl = '/deals';
            // if (isB2BCheck) {
            //     dealsUrl = '/deals/b2b';
            // }

            // TODO: check if B2B customer
            // Change What's New to Deals for promotional period

            const dealsItems = {
                label: __('Deals'),
                type: 'deals',
                sub_category: [],
                url: dealsUrl,
                badge
            };

            categoryArray.splice(1, 0, dealsItems);
        }

        if (!categoryArray.length) {
            return null;
        }

        const [{ sub_category = [], label: mainCategoriesTitle }] = categoryArray;
        const rUrl = `${url}`;

        return (
            <div block="Menu" elem="MainCategories first_menu" className={ activeMenu }>
                <ul
                  block="Menu"
                  elem="ItemList"
                  mods={ { type: 'main' } }
                  aria-label={ mainCategoriesTitle }
                >
                    { categoryArray.map(this.renderHeaderLevelItem) }
                </ul>
            </div>
        );
    };

    checkClick(item) {
        const { onCategoryClick } = this.props;
        setTimeout(() => {
            onCategoryClick(item);
            this.setState({
                vehicle: false
            });
        }, 100);
    }

    renderHeaderLevelItem = (item) => {
        const {
            label, sub_category, type, url, badge = null
        } = item;
        const { onCategoryClick, subMenu = {}, activeMenu } = this.props;

        let subMenuType = subMenu.type;
        if (isEmpty(subMenuType)) {
            subMenuType = '';
        }
        let normalLabel = 'normalLabel';
        if (type == subMenuType && activeMenu) {
            normalLabel = 'selectedLabel submenuVisible';
        }
        const pageUrl = window.location.href;

        let isActive = false;

        const { vehicle = true } = this.state;
        if (vehicle && pageUrl.includes('/vehicle/') && label.value === 'Vehicle') {
            normalLabel = 'selectedLabel submenuVisible';
            isActive = true;

            return (
                <li key={ label } block="Menu" elem="Item" className={ normalLabel }>
                    <div
                      onClick={ this.checkClick(item) }
                      tabIndex="0"
                      block="Menu"
                      elem="SubCatLink"
                      role="button"
                    >
                        { label }
                    </div>
                </li>
            );
        }

        // Check if B2B customer (Use local storage check)
        // const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

        // Is Black Friday Promotion?
        // const blackFridayPromo = getBlackFridayPromo();
        // const blackFridayPromoActive = blackFridayPromo?.active ?? false;

        // Is Black Friday Promotion?
        // const xmasPromo = getXmasPromo();
        // const xmasPromoActive = xmasPromo?.active ?? false;

        // Deals link
        /* if (type === 'deals') {
            let dealsLink = '/deals';
            if (isB2B) {
                dealsLink = '/deals/b2b';
            }

            let newLabel = '';
            let extraClass = '';

            // Black Friday / Cyber Sale
            // let newLabel = __('NEW');
            if (blackFridayPromoActive) {
                newLabel = 'Black Friday';
                extraClass = 'promo-black-friday';
                if (isEurStore()) {
                    newLabel = 'Cyber';
                    extraClass = 'promo-cyber-sale';
                }
            }

            // Xmas Promo
            if (xmasPromoActive) {
                if (!isB2B) {
                    newLabel = 'Daily';
                }
                extraClass = 'promo-xmas';
                // if (isEurStore()) {
                //     newLabel = 'Cyber';
                //     extraClass = 'promo-cyber-sale';
                // }
            }

            // Set selected label on deals page
            if (pageUrl.includes('/deals')) {
                normalLabel = 'selectedLabel';
            }

            return (
                <li key={ label } block="Menu" elem="Item" className={ `${normalLabel} ${extraClass}` }>
                    <Link
                      tabIndex="0"
                      key={ label }
                      block="Menu"
                      elem="SubCatLink"
                      to={ dealsLink }
                      className="deals-link"
                      role="button"
                      onClick={ () => scrollToTop() }
                    >
                        <span className={`deals-badge ${extraClass}`}>
                            { newLabel }
                        </span>
                        { label }
                    </Link>
                </li>
            );
        } */

        // Links with url set
        if (!isEmpty(url)) {
            if (url.includes('/deals') && pageUrl.includes('/deals')) {
                isActive = true;
            }
            if (url.includes('/whats-new') && pageUrl.includes('/whats-new')) {
                isActive = true;
            }

            let isActiveColor = '';
            if (url.includes('/deals')) {
                // If url contains deals, show red link color
                isActiveColor = '#dd3526';
            }

            return (
                <li key={ label } block="Menu" elem="Item" className={isActive ? 'selectedLabel2' : normalLabel} style={{ color: isActiveColor }}>
                    <Link
                      to={ url }
                    //   onClick={ () => onCategoryClick(item) }
                      onClick={() => {
                          onCategoryClick(item);
                          scrollToTop();
                      }}
                      tabIndex="0"
                      block="Menu"
                      elem="SubCatLink"
                      role="button"
                    >
                        { (badge) && (
                            <span className="link-badge">
                                { badge }
                            </span>
                        )}

                        { label }
                    </Link>
                </li>
            );
        }

        return (
            <li key={ label } block="Menu" elem="Item" className={ normalLabel }>
                <div
                  onClick={ () => onCategoryClick(item) }
                  tabIndex="0"
                  block="Menu"
                  elem="SubCatLink"
                  role="button"
                >
                    { (badge) && (
                        <span className="deals-badge">
                            { badge }
                        </span>
                    )}

                    { label }
                    <FontAwesomeIcon icon={ faChevronDown } />
                </div>
            </li>
        );
    };

    render() {
        const { closeMenu } = this.props;

        return (
            <div
              block="Menu"
              elem="MenuWrapper"
            //   onMouseLeave={ closeMenu }
            >
                { this.renderHeaderLevel() }
                { this.renderTopLevel() }
                { this.renderVehicleFinder() }
            </div>

        );
    }
}

export default HeaderMenuComponent;
